<template>
  <div class="eject-alarm-wrapper" v-if="!hidden" :style="`${!visible ? 'width:300px' : ''}`">
    <div class="eject-alarm-title">
      <h2 @click="getData">报警弹窗</h2>
      <div class="eject-alarm-close">
        <i class="el-icon-minus" @click="visible = !visible"></i>
        <i class="el-icon-close iml-1" @click="closeHandler"></i>
      </div>
    </div>
    <el-collapse-transition>
    <div class="eject-alarm-body" v-show="visible">
      <el-table :data="list" height="300px" @row-click="goLocation">
        <el-table-column label="设备号" prop="SIM2" width="125">
          <span slot-scope="{row}" class="row-SIM2" @click.stop="goLocation(row)">{{row.SIM2||'--'}}</span>
        </el-table-column>
        <el-table-column label="报警类型" prop="AlarmType" width="110"></el-table-column>
        <el-table-column label="报警时间" prop="RcvTime" width="135">
          <template slot-scope="{row}">{{miment(row.RcvTime).format()}}</template>
        </el-table-column>
        <el-table-column label="车主电话" prop="Mobile" width="100">
          <template slot-scope="{row}">{{row.Mobile||'--'}}</template>
        </el-table-column>
        <el-table-column label="车架号" prop="VIN" width="160">
          <template slot-scope="{row}">{{row.VIN||'--'}}</template>
        </el-table-column>
        <el-table-column label="操作" min-width="50">
          <div slot-scope="{row, column, $index}" class="closebtn">
            <i class="el-icon-close ipa-0" @click.stop="removeRow($index)"></i>
          </div>
        </el-table-column>
      </el-table>
    </div>
    </el-collapse-transition>
  </div>  
</template>

<script>
import {QueryRecentAlarm} from '@/api/home'
import miment from 'miment'
export default {
  name: 'pop-alarm',
  props: {
  },
  data() {
    return {
      visible: false,
      hidden: false,
      list: []
    }
  },
  methods: {
    startGetData() {
      clearInterval(window._pop_alarm_timer)
      window._pop_alarm_timer = setInterval(this.getData, 60000)
    },
    endGetData() {
      clearInterval(window._pop_alarm_timer)
    },
    getData() {
      if (this.hidden) {
        return
      }

      QueryRecentAlarm().then(ret => {
        this.list = ret.data
        if (this.list.length > 0) {
          this.visible = true
        }
      }).catch(() => {

      })
    },
    closeHandler() {
      this.hidden = true
      let img = require('../../assets/icon/报警.gif')
      this.$message({
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: `弹窗已关闭，<span style="font-weight: 600; padding: 4px;" >刷新页面</span> 或 <span style="font-weight: 600; padding: 4px;">点击<img src="${img}"/></span>开启`,
        showClose: true
      });
    },
    removeRow(index) {
      this.list.splice(index, 1)
    },
    goLocation(row) {
      this.$router.push(`/online/monitor?action=addTaskList&objectid=${row.ObjectID}`)
    }
  },
  beforeDestroy() {
    this.endGetData()
  },
  created() {
    this.miment = miment
    this.$root.$on('pop-alarm:show', () => {
      this.hidden = false
      this.visible = true
    })
    this.startGetData()
  }
}
</script>

<style lang="scss" scoped>
.eject-alarm-wrapper {
  box-shadow: 0 0 5px 0 rgba(0,0,0,.15);
  transition: all .3s linear;
  .eject-alarm-title {
    background-color: rgba(0, 0, 0, .8);
    text-align: center;
    padding: 8px 12px;
    border: 1px solid #DDD;
    border-top-left-radius: 4px;
    h2 {
      font-weight: 500;
      display: inline-block;
      font-size: 14px;
      color: #FFF;
    }

    .eject-alarm-close {
      float: right;
      i {
        color: #FFF;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          color: #DDD;
        }
      }
    }
  }
  .eject-alarm-body {
    border: 1px solid #DDD;
    border-top: 0;
  }
}

.closebtn i {
  cursor: pointer;
}

.row-SIM2:hover {
  cursor: pointer;
  opacity: .8;
}
</style>